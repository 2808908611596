<template>
  <lazy-component class="inspirations">
    <div style="margin-bottom:20px">
      <div class="inspirationItemList">
        <div class="inspirationListPanel">
          <!-- <div style="width:100%;height:50vh;display: flex;" v-bind:style="[$mq === 'sm' || $mq === 'ssm'? {'height': '35vh'} : {}]">
            <img v-if="selectedInspiration.images != undefined && selectedInspiration.images.length > 0" class="inspirationDetailImage" style="object-fit: cover;width:100%;height:100%" @click="showImageViewer(true, 0)" :src="selectedInspiration.images[0]" alt="">
            <img v-else class="inspirationDetailImage" style="object-fit: cover;width:100px;height:100px;margin-left: auto;margin-right: auto;align-self: center;" :src="'../images/logo.gif'" alt="">
          </div> -->
          <div style="margin-top:20px"> 
            <div style="display:flex;">
              <div class="inspirationDetailTopText1" >
                {{selectedInspiration.userName}}  •  {{moment(selectedInspiration.createdDate).format("MMMM DD, YYYY")}}  
                <!-- • <a-icon type="message" theme="filled" :style="{ fontSize: '12px' }" />  {{selectedInspiration.commetCount}} -->
              </div>
            </div>
            <div class="inspirationDetailTopText2">{{selectedInspiration.title}}</div>


            <div class="inspirationDetailParent">
              <div v-for="_info, _index in selectedInspiration.detailInfo" :key="_index">
                <div v-if="_info.type == 'Text'" class="inspirationDetailTopText3">
                  {{_info.content}}
                </div>
                <div v-else-if="_info.type == 'Image'" class="inspirationDetailImageDiv">                  
                  <div style="width:100%;height:50vh;margin-top:26px;padding: 1px;" v-bind:style="[$mq === 'sm' || $mq === 'ssm'? {'height': '30vh'} : {}]" v-show="_info.images != undefined && _info.images.length > 0">
                    <img class="inspirationDetailImage" style="object-fit: cover;width:100%;height:100%" @click="showImageViewerSub(true, 0, _info.images)" :src="_info.images != undefined && _info.images.length > 0 ? _info.images[0] : ''" alt="">
                  </div>

                  <div style="margin-top: 26px;width:100%;" v-if="_info.images != undefined && _info.images.length > 2" >
                    <div style="width:100%;height:25vh;margin-top:0px;padding: 1px;" v-bind:style="[$mq === 'sm' || $mq === 'ssm'? {'height': '15vh'} : {}]" v-show="_info.images != undefined && _info.images.length > 1">
                      <img class="inspirationDetailImage" style="object-fit: cover;width:100%;height:100%" @click="showImageViewerSub(true, 1, _info.images)" :src="_info.images != undefined && _info.images.length > 1 ? _info.images[1] : ''" alt="">
                    </div>
                    <div style="width:100%;height:25vh;margin-top:0px;padding: 1px;" v-bind:style="[$mq === 'sm' || $mq === 'ssm'? {'height': '15vh'} : {}]" v-show="_info.images != undefined && _info.images.length > 2">
                      <img class="inspirationDetailImage" style="object-fit: cover;width:100%;height:100%" @click="showImageViewerSub(true, 2, _info.images)" :src="_info.images != undefined && _info.images.length > 2 ? _info.images[2] : ''" alt="">
                    </div>
                  </div>
                  <div style="display:flex;margin-top: 26px;width:100%;padding: 1px;" v-else-if="_info.images != undefined && _info.images.length > 1">
                    <div style="width:100%;height:50vh;margin-top:0px;" v-bind:style="[$mq === 'sm' || $mq === 'ssm'? {'height': '30vh'} : {}]" v-show="_info.images != undefined && _info.images.length > 1">
                      <img class="inspirationDetailImage" style="object-fit: cover;width:100%;height:100%" @click="showImageViewerSub(true, 1, _info.images)" :src="_info.images != undefined && _info.images.length > 1 ? _info.images[1] : ''" alt="">
                    </div>
                  </div>
                </div>
                
                <div v-else-if="_info.type == 'Hyper Link'" class="inspirationDetailTopText3">  
                  <!-- hyperlinkTitle -->
                  <!-- hyperlinkURL -->
                  <a style="color:#00c2cb;font-weight: 600;" :href="_info.hyperlinkURL" target="_blank">{{_info.hyperlinkTitle}}</a>
                </div>
              </div>

              <div class="instagramSocialMainParent">
                <div class="inspirationFollowTitle">Love it? Share it:</div>
                <ul class="inspirationShareMain">
                  <li class="share-twitter">
                    <ShareNetwork
                        network="twitter"
                        :url="currentFullUrl"
                        title="I share it"
                      >
                      <img class="inspirationShareIcon" src="../assets/images/cell-share-twitter.png">
                    </ShareNetwork>
                  </li>
                  <li class="share-facebook">
                    <ShareNetwork
                        network="facebook"
                        :url="currentFullUrl"
                        title="I share it"
                      >
                      <img class="inspirationShareIcon" src="../assets/images/cell-share-facebook.png">
                    </ShareNetwork>
                  </li>
                  <li class="share-reddit">
                    <ShareNetwork
                        network="reddit"
                        :url="currentFullUrl"
                        title="I share it"
                      >
                      <img class="inspirationShareIcon" src="../assets/images/cell-share-reddit.png">
                    </ShareNetwork>
                  </li>
                  <li class="share-tumblr">
                    <ShareNetwork
                        network="tumblr"
                        :url="currentFullUrl"
                        title="I share it"
                      >
                      <img class="inspirationShareIcon" src="../assets/images/cell-share-tumblr.png">
                    </ShareNetwork>
                  </li>
                  <li class="share-pinterest">
                    <ShareNetwork
                        network="pinterest"
                        :url="currentFullUrl"
                        title="I share it"
                      >
                      <img class="inspirationShareIcon" src="../assets/images/cell-share-pinterest.png">
                    </ShareNetwork>
                  </li>
                  <li class="share-end">

                  </li>
                </ul>
              </div>

            </div>
            <!-- <div class="inspirationDetailTopText3">{{selectedInspiration.detail}}</div> -->
          </div>
          
          <!-- <div style="width:100%;height:50vh;margin-top:40px" v-bind:style="[$mq === 'sm' || $mq === 'ssm'? {'height': '35vh'} : {}]" v-show="selectedInspiration.images != undefined && selectedInspiration.images.length > 1">
            <img class="inspirationDetailImage" style="object-fit: cover;width:100%;height:100%" @click="showImageViewer(true, 1)" :src="selectedInspiration.images != undefined && selectedInspiration.images.length > 1 ? selectedInspiration.images[1] : ''" alt="">
          </div>

          <div style="display:flex;margin-top: 26px;" v-if="selectedInspiration.images != undefined && selectedInspiration.images.length > 3" v-bind:style="[$mq === 'sm'? {'margin-top': '10px'} : {}]">
            <div style="width:50%;height:30vh;margin-top:0px;padding-right: 15px;" v-bind:style="[$mq === 'sm' || $mq === 'ssm'? {'height': '15vh', 'padding-right': '5px'} : {}]" v-show="selectedInspiration.images != undefined && selectedInspiration.images.length > 2">
              <img class="inspirationDetailImage" style="object-fit: cover;width:100%;height:100%" @click="showImageViewer(true, 2)" :src="selectedInspiration.images != undefined && selectedInspiration.images.length > 2 ? selectedInspiration.images[2] : ''" alt="">
            </div>
            <div style="width:50%;height:30vh;margin-top:0px;padding-left: 15px;" v-bind:style="[$mq === 'sm' || $mq === 'ssm'? {'height': '15vh', 'padding-left': '5px'} : {}]" v-show="selectedInspiration.images != undefined && selectedInspiration.images.length > 3">
              <img class="inspirationDetailImage" style="object-fit: cover;width:100%;height:100%" @click="showImageViewer(true, 3)" :src="selectedInspiration.images != undefined && selectedInspiration.images.length > 3 ? selectedInspiration.images[3] : ''" alt="">
            </div>
          </div>
          <div style="display:flex;margin-top: 26px;" v-else-if="selectedInspiration.images != undefined && selectedInspiration.images.length > 2">
            <div style="width:100%;height:50vh;margin-top:0px;" v-bind:style="[$mq === 'sm' || $mq === 'ssm'? {'height': '35vh'} : {}]" v-show="selectedInspiration.images != undefined && selectedInspiration.images.length > 2">
              <img class="inspirationDetailImage" style="object-fit: cover;width:100%;height:100%" @click="showImageViewer(true, 2)" :src="selectedInspiration.images != undefined && selectedInspiration.images.length > 2 ? selectedInspiration.images[2] : ''" alt="">
            </div>
          </div> -->
          
          <a-modal
            class="inspirationModel"
            v-model="visibleImageViewer"
            title=""
            :footer="null"
            :width="'auto'"
            :dialog-style="{ 'display': 'flex', 'align-items': 'center', 'max-width': '1080px', 'top': '0', 'height': '100%'}"
          >
            <image-previewer v-if="visibleImageViewer" :imageData="selectedInspiration.images" :imageIndex="indexImageViewer" @close="showImageViewer(false)"/>
          </a-modal>

          
          <a-modal
            class="inspirationModel"
            v-model="visibleImageViewerSub"
            title=""
            :footer="null"
            :width="'auto'"
            :dialog-style="{ 'display': 'flex', 'align-items': 'center', 'max-width': '1080px', 'top': '0', 'height': '100%'}"
          >
            <image-previewer v-if="visibleImageViewerSub" :imageData="subImages" :imageIndex="indexImageViewerSub" @close="showImageViewerSub(false)"/>
          </a-modal>
          
        </div>
        <div class="inspirationSearchPanel" v-bind:style="[$mq === 'sm' || $mq === 'ssm'? {'width': '100%'} : {'width':'30%'}]">
          <!-- <a-input-search placeholder="Type a keyword and hit enter" style="width: 80%;margin-left:10%;margin-top:20px;" @search="onSearch" />
          <a-list style="margin-top:20px;" v-show="searchData.length > 0" :data-source="searchData">
            <a-list-item slot="renderItem" slot-scope="item">
              <div style="width:100%;display:flex;cursor: pointer;" @click="gotoDetail(item._id)">          
                <div style="display: contents;">
                  <img style="object-fit: cover;width:80px;height:60px" :src="item.mainImage != undefined && item.mainImage.length > 0 ? item.mainImage[0] : ''" alt="">
                </div>
                <div style="margin:5px">
                  <div class="inspirationSearckTitle">{{item.title}}</div>
                  <div class="inspirationSearchDate">{{moment(item.createdDate).format("MMMM DD, YYYY")}}</div>
                </div>
              </div>
            </a-list-item>
          </a-list> -->
          <div style="margin-top:0px;">
            <!-- <div class="inspirationSearchTitle">Categories</div>
            <div style="margin-bottom:20px">
              <div v-for="item in tagList" :key="item.key">
                <dir class="inspirationLine"></dir>
                <div style="display:flex;cursor: pointer;" @click="OnClickTagCount(item)"> 
                  <div class="inspirationTagCellTitle">{{item.name}}</div>
                  <div class="inspirationTagCellCount">({{countInfo[item.key] != undefined ? countInfo[item.key] : 0}})</div>
                </div>
              </div>
            </div> -->
            <div class="inspirationSearchTitle">Tags</div>
            <dir class="inspirationLine"></dir>
            <div style="display:flex;flex-flow: row wrap;margin-bottom:5px">
              <div style="cursor: pointer;border-radius: 3px;" v-for="item in showTags" :key="item.key" class="inspirationTagCell" @click="OnClickTagTap(item)">{{item.name}}</div>
            </div>
          </div>
          <div class="instagramSocialMainParent">
            <div class="inspirationFollowTitle">KEEP IN TOUCH</div>
            <ul class="inspirationShareMain">
              <li class="ssi-email">
                <a href="mailto:hello@vuecollection.com">
                  <img class="inspirationShareIcon" src="../assets/images/icon-share-email.png">
                </a>
              </li>
              <li class="ssi-facebook">
                <a href="https://www.facebook.com/vuecollection/">
                  <img class="inspirationShareIcon" src="../assets/images/icon-share-facebook.png">
                </a>
              </li>
              <li class="ssi-instagram">
                <a href="https://www.instagram.com/vuecollection">
                  <img class="inspirationShareIcon" src="../assets/images/icon-share-instagram.png">
                </a>
              </li>
              <!-- <li class="ssi-twitter">
                <a href="https://www.twitter.com/vue_maldivescollection">
                  <img class="inspirationShareIcon" src="../assets/images/icon-share-twitter.png">
                </a>
              </li>
              <li class="ssi-youtube">
                <a href="https://www.youtube.com/vue_maldivescollection">
                  <img class="inspirationShareIcon" src="../assets/images/icon-share-youtube.png">
                </a>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </lazy-component>
</template>
<script>
import { api } from '../helpers/Helpers';
import moment from 'moment';
import imagePreviewer from './ListItems/image-previewer.vue';

const searchData = [];

export default {
  components: { imagePreviewer },
  data() {
    return {
      searchData,
      pagination: {
        
      // eslint-disable-next-line no-unused-vars
        onChange: page => {
          
        },
        pageSize: 4,
      },

      alllist:[],
      inspirationId:'',
      selectedInspiration:{},
      countInfo:{},
      tagList : [],
      showTags : [],

      allImageList : [],
      
      visibleImageViewer: false,
      indexImageViewer: 0,


      visibleImageViewerSub: false,
      indexImageViewerSub: 0,
      subImages : [],

      currentFullUrl:''
    };
  },
  
  created: function () {
  },
  mounted: function(){
    this.initData();
  },
  
  methods: {
    moment,
    
    initData:async function(){
      await this.getInspirationTags();
      this.inspirationId = this.$route.query.id;
      this.getInspirationInfo(this.inspirationId);
      this.currentFullUrl = window.location.href;
      this.getInspirations();
    },

    getInspirationTags:async function(){
        const res = await api.getInspirationTagInfo();
        if (res != undefined && res.length > 0 && res[0].datalist != undefined){
            this.tagList = res[0].datalist;
        }
    },
    onSearch(value) {
      this.searchData = [];
      for (let item in this.alllist){
        if (this.alllist[item].title.includes(value))
          this.searchData.push(this.alllist[item]);
        if (this.searchData.length > 2)
          break;
      }
    },

    getInspirationInfo:async function(_id){
      const res = await api.getInspirationById(_id);
      if (res != undefined){

        var userName = '';
        if (res.userInfo != undefined)
            userName = res.userInfo.firstName + " " + res.userInfo.lastName;
            
        var detail = res.detail;                
        var title = res.title;                

        let detailInfo = res.detailInfo;
        let imageTypeList = detailInfo.filter(obj => obj.type == "Image");
        this.allImageList = [];
        if (imageTypeList.length > 0){
          for (let iType = 0; iType < imageTypeList.length; iType++){
            if (imageTypeList[iType] != undefined && imageTypeList[iType].images != undefined && imageTypeList[iType].images.length > 0)
            this.allImageList = this.allImageList.concat(imageTypeList[iType].images)
          }
        }

        let textTypeList = detailInfo.filter(obj => obj.type == "Text");
        
        if (textTypeList.length > 0){
          if (textTypeList[0] != undefined && textTypeList[0].content != undefined)
            detail = textTypeList[0].content;              
        }

        const cellInfo = {
            title: title,
            detail: detail,
            detailInfo: res.detailInfo,
            userName: userName,
            
            tags: res.tags,
            images: this.allImageList,
            commetCount: res.commetCount,
            createdDate: new Date(res.createdDate),
            id: res._id,
        };
        this.showTags = [];
        let _tags = res.tags;
        if (_tags != undefined){
          for(let i = 0 ; i < _tags.length; i++){
            let _index = this.tagList.findIndex(obj => obj.key == _tags[i])
            if (_index != -1)
              this.showTags.push(this.tagList[_index]);
          }
        }
        this.selectedInspiration = cellInfo;


      }

    },
    getInspirations: async function() {
        const res = await api.getInspirations();
        if (res != undefined){
          this.alllist = res;
          this.getTagCountInfo(res);
        }
    },
    getTagCountInfo(res){
      for (let cell in this.tagList){
        this.countInfo[this.tagList[cell].key] = this.getCellCountInfo(res, this.tagList[cell].key);
      }
      const tempInfo = this.countInfo;
      this.countInfo = {};
      this.countInfo = tempInfo;
    },
    
    getCellCountInfo(res, _tagKey){
      var result = 0;
      for (let item in res){
        if(res[item].tags.includes(_tagKey))
          result += 1;
      }
      return result;
    },


    gotoDetail(id){
      this.$router.replace({ path: '/inspirationsDetail/', query: {id:id}})
      this.$router.go(0);
    },
    
    showImageViewer(isShow, index){
        this.visibleImageViewer = isShow;
        this.indexImageViewer = index;
    },

    
    showImageViewerSub(isShow, subIndex, _images){
        if (isShow){
          this.indexImageViewerSub = subIndex;
          this.subImages = _images;
        }
        this.visibleImageViewerSub = isShow;
    },

    OnClickTagTap(_info){
      this.$router.push({ path: '/inspiration/', query: {key:_info.key}})
    },
    OnClickTagCount(_info){
      this.$router.push({ path: '/inspiration/', query: {key:_info.key}})
    }
  },
};
</script>
<style></style>
